<template>
  <section>
    <div class="show-table-maxscreen">
      <div class="flex-end">
        <v-button
          class="button-theme mg-r-8"
          @click="resetFilter">Clear</v-button>
      </div>
      <el-table
        :data="tableData"
        :header-row-class-name="'table-header'"
        style="width: 100%"
      >
        <div>
          <el-table-column width="55"></el-table-column>
          <el-table-column
            prop="date"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Statement of Account</span>
                <v-date-picker
                  v-model="date"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                class="link hand flex flex-aligin"
                @click="handlerMonth(scope.row)">
                <font-awesome-icon icon="link"></font-awesome-icon>
                <span>{{showYearMonth(scope.row.month)}}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Total Net Premium</span>
                <v-date-picker placeholder="Range"></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span >{{scope.row.total_amount|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Settle Date</span>
                <v-date-picker
                  v-model="date"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="link">{{showMonth(scope.row.settle_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Remark</span>
                <v-input placeholder="Search"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span >{{scope.row.remark}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Statement</span>
                <span class=""></span>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                class="link"
                @click="handlerDown(scope.row)">Download</span>
            </template>
          </el-table-column>
          <el-table-column
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Actions</span>
                <span></span>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                class="red"
                @click="handlerUndo(scope.row)">Undo</span>
            </template>
          </el-table-column>
        </div>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <div >
                <el-row>
                  <v-row-col>
                    <span class="is-bold">Statement Account</span>
                    <v-date-picker
                      v-model="date"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"></v-date-picker>
                  </v-row-col>

                  <v-row-col >
                    <span class="is-bold">Total Comm. to Referrer</span>
                    <v-input></v-input>
                  </v-row-col>
                  <v-row-col >
                    <span class="is-bold">Settle Date</span>
                    <v-date-picker
                      v-model="date"
                      type="daterange"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"></v-date-picker>
                  </v-row-col>
                  <v-row-col >
                    <span class="is-bold">Remark</span>
                    <v-input ></v-input>
                  </v-row-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table">
        <div
          @click="rowClick">
          <div
            class="tr">
            <span>Statement of Account</span>
            <span>{{showYearMonth(item.month)}}</span>
          </div>
          <div
            class="tr">
            <span>Total Comm. to Reffer</span>
            <span>{{item.total_amount|formatMoney(2,true)}}</span>
          </div>
          <div>
            <div
              class="tr">
              <span>Settle Date</span>
              <span>{{showMonth(item.settle_date)}}</span>
            </div>
            <div
              class="tr">
              <span>Remark</span>
              <span>{{item.remark}}</span>
            </div>
            <div
              class="tr">
              <span>Statement</span>
              <span
                class='link'
                @click="handlerDown(item)">Download</span>
            </div>
          </div>
          <div
            class="tr">
            <span>Actions</span>
            <span
              class="red"
              @click="handlerUndo(item)">Undo</span>
          </div>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange" />
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinFormat from '@/mixins/format'
import { mapState, mapActions } from 'vuex'
import {referrerUndo,getReferrerStatement,referrerStatementDown} from '@api/referrer'
import {insurerUndo,getInsurerStatement,insurerStatementDown} from '@api/insurer'
import { downloadFile } from '@/utils/download'
import MixinFilter from '@/mixins/filter'
export default {
  name: 'settlements',
  mixins: [MixinOptions,MixinFormat,MixinFilter],
  props:{
    type:{
      type:Number,
      default:1 //1 insurer 2 referral
    },
  },
  data(){
    return {
      summary:{},
      show:false,
      date:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      tableData:[],
      filterData:{
        date:'',
      },
      filterTableKey:'insurerSettled'
    }
  },
  created(){
    this.getList()
  },
  methods:{
    ...mapActions('statement', ['setStatement','setStatementParams']),
    rowClick(){
      // this.$router.push('/quotation/premium/info')
    },
    handlerDown({settlement_id,month}){
      let params = {
        settlement_id,
        month
      }
      if(this.type==1){
        this.network().insurerStatementDown(params)
      }else{
        this.network().referrerStatementDown(params)
      }
    },
    handlerMonth(item){
      let {month,settlement_id} =item
      let params = {}
      //insurer
      if(this.type==1){
        params = {
          month:month,
          settlement:settlement_id,
          company:this.$route.params.companyId,
        }
      }else{
        params={
          month:month,
          settlement:settlement_id,
          referral:this.$route.params.id,
        }
      }
      this.setStatementParams(params)
      this.updateFilter()
      this.$router.push({
        path:'/member/statement/list',
        query:{
          type:this.type,
          // ...params
        }
      })
      this.setStatement(item)
    },
    handlerUndo({month,settlement_id}){
      this.$ConfirmBox({
        title: 'Undo Settlement',
        message: 'Are you sure you want to undo this settlement? This action cannot be reversed',
        confirmBtn:'Undo Statement',
        classButton:true
      }).then(() => {
        if(this.type==1){
          let params = {
            month:month,
            settlement_id:settlement_id,
            company_id:this.$route.params.companyId,
          }
          this.network().handlerInsurerUndo(params)

        }else{
          let params = {
            month:month,
            settlement_id:settlement_id,
            referral_id:this.$route.params.id,
          }
          this.network().handlerReferrerUndo(params)
        }
      })
    },
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    getList(){
      if(this.type==1){
        this.network().getInsurerStatement()
      }
      else if(this.type==2){
        this.network().getReferrerStatement()
      }
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.getList()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.getList()
    },
    handlerChange(size){
      this.pageConfig.size =size
      this.getList()
    },
    network() {
      return {
        getInsurerStatement:async (params)=>{
          const { data } = await getInsurerStatement({
            company_id:this.$route.params.companyId,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        getReferrerStatement:async (params)=>{
          const { data } = await getReferrerStatement({
            referral_id:this.$route.params.id,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        handlerReferrerUndo: async (params) => {
          const { data } = await referrerUndo(params)
          this.currentChange(1)
        },
        handlerInsurerUndo: async (params) => {
          const { data } = await insurerUndo(params)
          // this.$emit('statementRefresh')
          this.currentChange(1)
        },
        insurerStatementDown: async (params) => {
          params = {
            ...params,
            company_id:this.$route.params.companyId,
          }
          let data = await insurerStatementDown(params)
          downloadFile(data)
        },
        referrerStatementDown: async (params) => {
          params = {
            ...params,
            referral_id:this.$route.params.id,
          }
          let data = await referrerStatementDown(params)
          downloadFile(data)
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../assets/css/policyList';
.flex-end{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
